<template>
  <div class="updownManage">
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="已上架" name="first"> </el-tab-pane>
      <el-tab-pane label="未上架" name="second"> </el-tab-pane>
    </el-tabs>
    <upDownComponent :activeName="activeName" :up="activeName === 'first'" />
  </div>
</template>
<script>
import upDownComponent from './upDownComponent.vue'

export default {
  data() {
    return {
      activeName: 'first'
    }
  },
  components: {
    upDownComponent
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.updownManage {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  .title {
    line-height: 16px;
    font-size: 16px;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }
}
</style>
