<template>
  <page>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="商品名称" prop="goodsName">
          <el-input v-model="params.goodsName" />
        </el-form-item>
        <el-form-item label="品牌" prop="goodsBrand">
          <el-select v-model="params.goodsBrand" clearable filterable>
            <el-option
              v-for="item in brandList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="条形码" prop="barNo">
          <el-input v-model="params.barNo" />
        </el-form-item>
        <el-form-item label="商品一级分类" prop="clazzId1">
          <el-select v-model="params.clazzId1" clearable>
            <el-option
              v-for="item in typeList1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="商品二级分类" prop="clazzId2">
          <el-select v-model="params.clazzId2" clearable>
            <el-option
              v-for="item in typeList2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </template>

    <template slot="headerInfo">
      <el-button
        v-auth="`${up ? 'supplierGoods:updateState' : 'supplierGoods:goodsUp'}`"
        @click="downHandler(false)"
        type="primary"
        :disabled="!multipleSelection.length"
      >
        {{ up ? '批量下架' : '批量上架' }}
      </el-button>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        />
        <el-table-column show-overflow-tooltip prop="barNo" label="条形码" />
        <el-table-column
          show-overflow-tooltip
          prop="clazzName1"
          label="商品一级分类"
          min-width="100"
        />
        <!-- <el-table-column
          show-overflow-tooltip
          prop="clazzName2"
          label="商品二级分类"
        /> -->
        <el-table-column show-overflow-tooltip prop="brandName" label="品牌" />
        <el-table-column show-overflow-tooltip prop="state" label="商品状态">
          <template slot-scope="{ row }">
            <span>{{ +row.state === 1 ? '正常' : '待上架' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="
                `${up ? 'supplierGoods:updateState' : 'supplierGoods:goodsUp'}`
              "
              @click="downHandler(row.id)"
              type="text"
              size="mini"
              >{{ up ? '下架' : '上架' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />
  </page>
</template>

<script>
export default {
  props: ['activeName', 'up'],
  data() {
    return {
      moveVisible: false,
      currentItem: null,
      loading: false,
      tableData: [],
      brandList: [],
      multipleSelection: [],
      typeList1: [],
      typeList2: [],
      params: {
        limit: 20,
        page: 1,
        goodsName: '',
        brandName: '',
        barNo: '',
        clazzId1: '',
        // clazzId2: '',
        state: 1
      },
      totalCount: 0
    }
  },
  created() {
    this.getTypeList()
    this.getBrandList()
    this.getData()
  },
  watch: {
    activeName(val) {
      this.params.state = val === 'first' ? 1 : 3
      this.getData()
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async getData() {
      try {
        this.loading = true
        const res = await this.$api.productList.getProductList(this.params)

        this.tableData = (res.page && res.page.list) || []
        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    async downHandler(id) {
      const txt = this.up ? '下架' : '上架'

      try {
        await this.$confirm(
          id ? `是否要${txt}该商品？` : `是否要批量${txt}所选商品？`,
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true
          await this.$api.productList.batchUpdateState({
            goodsIdList: id ? [id] : this.multipleSelection,
            state: this.up ? 3 : 1
          })
          this.$message.success(`${txt}成功`)
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },

    async getTypeList() {
      try {
        const res = await this.$api.classification.getClazzList({
          limit: 1000,
          page: 1
        })
        this.typeList1 = (res.clazzList.list || []).map(item => {
          return {
            label: item.clazzName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getTypeList()
        // this.typeList1 = (res.list || []).map(item => {
        //   return {
        //     label: item.clazzName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async getBrandList() {
      try {
        const res = await this.$api.brandList.getGoodsBrandList({
          limit: 1000,
          page: 1
        })

        this.brandList = (res.brandList.list || []).map(item => {
          return {
            label: item.brandName,
            value: item.id
          }
        })

        // const res = await this.$api.productList.getBrandList()
        // this.brandList = (res.list || []).map(item => {
        //   return {
        //     label: item.brandName,
        //     value: item.id
        //   }
        // })
      } catch (err) {}
    },

    async typeOneChange(val) {
      this.params.clazzId2 = ''
      if (!val) {
        this.typeList2 = []
      } else {
        try {
          const res = await this.$api.clazzList.getClazzList({
            parentId: val,
            limit: 1000,
            page: 1
          })

          this.typeList2 = (res.secondaryClazzList.list || []).map(item => {
            return {
              label: item.clazzName,
              value: item.id
            }
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
